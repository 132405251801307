<template>
  <div class="shopServerBox">
    <img src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/shopServerBg.png" class="backImage" />
    <div class="header" v-if="shareUserInfo">
      <div class="left">
        <img :src="shareUserInfo.companyLogo" />
        <div class="name">{{ shareUserInfo.openUserDTO.company.aliasName }}</div>
      </div>
      <div class="right">
        <img :src="shareUserInfo.openUserDTO.avatarFileUrl" />
        <div class="name">{{ shareUserInfo.openUserDTO.nickName }} {{$t('order.share')}}</div>
      </div>
    </div>
    <div class="shareContentBox">
      <template v-if="shareType == 0 || shareType == 2 || shareType == 4">
        <div class="list">
          <el-row :gutter="20" v-if="dataList.length">
            <el-col 
              :span="shareType == 2 ? 12 : 6" 
              v-for="(item, index) in dataList" 
              :key="index"
              @click.native="goServerDetail(item)">
              <shopItem v-if="shareType == 0" :item="item" />
              <serverItem v-if="shareType == 2" :item="item" />
              <caseItem v-if="shareType == 4" :item="item" />
            </el-col>
          </el-row>
          <el-empty 
            v-else 
            :description="$t('overallSituation.noData')" 
            :image="$emptyImage"
            :image-size="100"
          />
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="changePageSizeHandler"
            @current-change="changePageHandler"
            :current-page="params.page"
            :page-sizes="[20, 30, 40, 50, 100]"
            :total="total"
            background
            layout="prev, pager, next, sizes, total, jumper"
          />
        </div>
      </template>
      <template v-if="shareType == 1">
        <div class="detailItem">
          <div class="detailBox" v-if="shareShopItem">
            <el-image 
              fit="cover" 
              class="elImage" 
              :src="shareShopItem.picList.find(item => item.check) && shareShopItem.picList.find(item => item.check).fileUrl">
              <div slot="error" class="image-slot">
                <img :src="$emptyImage" />
                <p>{{ $t('No image') }}</p>
              </div>
            </el-image>
            <div class="checkImageBox">
              <div 
                class="imageItem" 
                v-for="(item, index) in shareShopItem.picList" 
                :key="index"
                :class="item.check ? 'active' : ''"
                @click="changeImage(item)">
                <img :src="item.fileUrl" />
              </div>
            </div>
            <div class="title">{{ shareShopItem.goodsName }}</div>
            <div class="description">{{ shareShopItem.description }}</div>
            <div class="price">
              $ <span>{{ (Number(shareShopItem.price)).toFixed(3) }}</span>
            </div>
            <div class="pageTitle">
              {{ $t('Attachment') }}
            </div>
            <fileBlocks
              type="message"
              isShare
              v-if="shareShopItem.attachmentUrl"
              background="#F7F9FC"
              :embedUrl="shareShopItem.attachmentUrl"
              :embedId="shareShopItem.attachmentId"
            />
          </div>
        </div>
      </template>
      <template v-if="shareType == 3">
        <div class="detailItem">
          <serverItem :item="shareServerItem" />
        </div>
      </template>
      <template v-if="shareType == 5">
        <div class="detailItem">
          <div class="detailBox" v-if="shareCaseItem">
            <el-image 
              fit="cover" 
              class="elImage" 
              :src="shareCaseItem.picList.find(item => item.check) && shareCaseItem.picList.find(item => item.check).fileUrl">
              <div slot="error" class="image-slot">
                <img :src="$emptyImage" />
                <p>{{ $t('No image') }}</p>
              </div>
            </el-image>
            <div class="checkImageBox">
              <div 
                class="imageItem" 
                v-for="(item, index) in shareCaseItem.picList" 
                :key="index"
                :class="item.check ? 'active' : ''"
                @click="changeImage(item)">
                <img :src="item.fileUrl" />
              </div>
            </div>
            <div class="title">{{ shareCaseItem.caseName }}</div>
            <div class="description">{{ shareCaseItem.description }}</div>
            <div class="tagList">
              <div 
                class="item line1" 
                v-for="(row, index) in shareCaseItem.tags"
                :key="index"
                :style="{
                  background: row.color
                }"
              >{{ row.content }}</div>
            </div>
            <div class="pageTitle">
              {{ $t('Attachment') }}
            </div>
            <fileBlocks
              type="message"
              isShare
              v-if="shareCaseItem.attachmentUrl"
              background="#F7F9FC"
              :embedUrl="shareCaseItem.attachmentUrl"
              :embedId="shareCaseItem.attachmentId"
            />
          </div>
        </div>
      </template>
      <template v-if="shareType == 7">
        <div class="detailItem">
          <div class="detailBox" v-if="shareFileItem">
            <div class="fileUrlBox">
              <i :class="`iconfont_file ${$isFileTypeIcon(shareFileItem.attachmentName.split('.')[1])}`"></i>
              <div class="fileTitle">{{ shareFileItem.attachmentName }}</div>
              <div class="fileSize">{{ shareFileItem.attachmentSize || '0.00MB' }}</div>
            </div>
            <div class="title">{{ shareFileItem.fileName }}</div>
            <div class="description">{{ shareFileItem.description }}</div>
            <div class="tagList">
              <div 
                class="item line1" 
                v-for="(row, index) in shareFileItem.tags"
                :key="index"
                :style="{
                  background: row.color
                }"
              >{{ row.content }}</div>
            </div>
            <div class="pageTitle">
              {{ $t('Attachment') }}
            </div>
            <fileBlocks
              v-if="shareFileItem.attachmentUrl"
              type="message"
              background="#F7F9FC"
              :embedUrl="shareFileItem.attachmentUrl"
              :embedId="shareFileItem.attachmentId"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="welcomeTip" v-if="shareUserInfo && isWelcome">
      <div class="welcomeBox">
        <div class="left">
          <img :src="shareCompanyLogo || 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/logoVip.png'" class="logo" />
          <div class="title">{{ $t('Welcome to Echola') }}</div>
          <div class="describe">{{ $t('Come on in Order Info') }}</div>
        </div>
        <div class="right">
          <el-button round @click="isWelcome = false">{{ $t('Continue browsing this page') }}</el-button>
          <el-button type="primary" @click="goLogin" round>{{ $t('Log in now') }}</el-button>
        </div>
      </div>
    </div>
    <shopDrawer ref="shopDrawer" isShare />
    <serverDrawer ref="serverDrawer" isShare />
    <caseDrawer ref="caseDrawer" isShare />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { 
  getOffSiteServerDetail, 
  getShareCompanyData, 
  getOffSiteServerList,
  getOffSiteCaseList,
  getOffSiteCaseDetail,
  getOffSiteFileDetail,
  getOffSiteShopList,
  getOffSiteShopDetail
} from "@/api/shopServer.js";
import shopItem from "@/components/shopServerManage/itemComponents/shop.vue";
import shopDrawer from "@/components/shopServerManage/detailDrawer/shop.vue";
import serverItem from "@/components/shopServerManage/itemComponents/server.vue";
import serverDrawer from "@/components/shopServerManage/detailDrawer/server.vue";
import caseItem from "@/components/shopServerManage/itemComponents/case.vue";
import caseDrawer from "@/components/shopServerManage/detailDrawer/case.vue";
import fileItem from "@/components/shopServerManage/itemComponents/file.vue";
import fileDrawer from "@/components/shopServerManage/detailDrawer/file.vue";
export default {
  components: {
    shopItem,
    shopDrawer,
    serverItem,
    caseItem,
    fileItem,
    serverDrawer,
    caseDrawer,
    fileDrawer
  },
  data() {
    return {
      shareType: 0,
      isWelcome: true,
      shareUserInfo: null,
      shareCompanyLogo: "",
      shareServerItem: {
        shippingCompanyName: "ESL",
        shippingCompanyLogoUrl: "https://echola-pre.oss-cn-hangzhou.aliyuncs.com/file/carrier/ESL4010x.png",
        departureName: "南沙港",
        departureCountry: "中国",
        transferName: "",
        destinationName: "江门港",
        destinationCountry: "中国",
        startTime: "2023-11-02 15:49:42",
        endTime: "2023-11-15 15:49:42"
      },
      shareCaseItem: null,
      shareFileItem: null,
      shareShopItem: null,
      params: {
        page: 1,
        size: 20
      },
      total: 0,
      dataList: []
    }
  },
  mounted() {
    this.shareType = this.$route.query.type;
    this.$i18n.locale = this.$route.query.lang;
    this.$store.dispatch("user/setLanguage", this.$route.query.lang);
    localStorage.setItem("lang", this.$route.query.lang);
    setTimeout(() => {
      this.getUserInfo();
      this.getShopServerData();
    }, 10);
  },
  methods: {
    getUserInfo() {
      getShareCompanyData(this.$route.query.token).then((data) => {
        this.shareUserInfo = data;
        this.shareCompanyLogo = data.platformLogoUrl;
      })
    },
    getShopServerData() {
      if(this.shareType == 0) {
        getOffSiteShopList({
          token: this.$route.query.token,
          ...this.params
        }).then((data) => {
          this.dataList = data.list;
          this.total = data.total;
        })
      }
      if(this.shareType == 1) {
        getOffSiteShopDetail({
          id: this.$route.query.id,
          token: this.$route.query.token,
        }).then((data) => {
          this.shareShopItem = {
            ...data,
            picList: data.picList ? data.picList.map((item, index) => ({
              ...item,
              check: index == 0
            })) : [],
          };
        })
      }
      if(this.shareType == 2) {
        getOffSiteServerList({
          token: this.$route.query.token,
          ...this.params
        }).then((data) => {
          this.dataList = data.list.map(item => ({
            ...item,
            shippingCompanyName: item.systemShippingCompanyDTO?.companyEnName,
            shippingCompanyId: item.systemShippingCompanyDTO?.shippingCompanyId,
            shippingCompanyLogoUrl: item.systemShippingCompanyDTO?.logoUrl,
          }));
          this.total = data.total;
        })
      }
      if(this.shareType == 3) {
        getOffSiteServerDetail({
          id: this.$route.query.id,
          token: this.$route.query.token,
        }).then((data) => {
          this.shareServerItem = {
            ...data,
            shippingCompanyLogoUrl: data.systemShippingCompanyDTO.logoUrl,
            shippingCompanyName: data.systemShippingCompanyDTO.companyEnName
          };
        })
      }
      if(this.shareType == 4) {
        getOffSiteCaseList({
          token: this.$route.query.token,
          ...this.params
        }).then((data) => {
          this.dataList = data.list.map(item => ({
            ...item,
            tags: JSON.parse(item.tags)
          }));
          this.total = data.total;
        })
      }
      if(this.shareType == 5) {
        getOffSiteCaseDetail({
          id: this.$route.query.id,
          token: this.$route.query.token,
        }).then((data) => {
          this.shareCaseItem = {
            ...data,
            picList: data.picList ? data.picList.map((item, index) => ({
              ...item,
              check: index == 0
            })) : [],
            tags: JSON.parse(data.tags)
          };
        })
      }
      if(this.shareType == 7) {
        getOffSiteFileDetail({
          id: this.$route.query.id,
          token: this.$route.query.token,
        }).then((data) => {
          this.shareFileItem = {
            ...data,
            tags: JSON.parse(data.tags)
          };
        })
      }
    },
    changeImage(row) {
      if(this.shareType == 1) {
        this.shareShopItem.picList.forEach((item) => {
          item.check = false;
        })
        row.check = !row.check;
        return;
      }
      this.shareCaseItem.picList.forEach((item) => {
        item.check = false;
      })
      row.check = !row.check;
    },
    goServerDetail(row) {
      if(this.shareType == 0) {
        this.$refs.shopDrawer.open(row);
      }
      if(this.shareType == 2) {
        this.$refs.serverDrawer.open(row);
      }
      if(this.shareType == 4) {
        this.$refs.caseDrawer.open(row);
      }
    },
    changePageHandler(page) {
      this.params.page = page;
      this.getShopServerData();
    },
    changePageSizeHandler(pageSize) {
      this.params.size = pageSize;
      this.getShopServerData();
    },
    goLogin() {
      this.$router.push({
        path: `/login`
      });
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../components/shopServerManage/detailDrawer/index.less";
.shopServerBox {
  position: relative;
  overflow-x: hidden;
  .backImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 233px;
    object-fit: cover;
    z-index: 0;
  }
  .header {
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 705px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    .left {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 65px;
        height: 49px;
        border-radius: 4px;
        object-fit: contain;
        background: #697c8e;
        border-radius: 4px;
      }
      .name {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color: #122545;
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .name {
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  .shareContentBox {
    min-width: 705px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 11;
    .el-empty {
      background: #ffffff;
      border-radius: 4px;
      height: 500px;
    }
    /deep/ .serverItemBox {
      .bottom {
        display: none;
      }
    }
    /deep/ .caseItemBox {
      .bottom {
        .right {
          display: none;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
    }
    .detailItem {
      width: 705px;
      margin: 0 auto;
      /deep/ .serverItemBox {
        height: 100vh;
        margin: 0;
        .content {
          margin-top: 40%;
        }
      }
      .detailBox {
        padding-bottom: 100px;
        background: #ffffff;
        border-radius: 4px;
      }
    }
  }
}
.welcomeTip {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  .welcomeBox {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 972px;
    box-shadow: 0 0 10px #cccccc;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 110px;
        margin-right: 40px;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #122545;
      }
      .describe {
        font-size: 14px;
        color: #637381;
        margin-left: 12px;
      }
    }
  }
}
</style>